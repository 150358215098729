/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { commonConfig } from "@cyna/common/config"
import {
  INTEGRATION_NAMES,
  INTEGRATIONS,
  INTEGRATIONS_STATUS,
  SUPPORTED_LANGUAGES,
} from "@cyna/common/constants"
import { languageValidator, urlValidator } from "@cyna/common/validators"
import { z } from "zod"

const validationSchema = z.object({
  apiBaseUrl: urlValidator,
  baseUrl: urlValidator,
  integrations: z.object(
    Object.fromEntries(
      INTEGRATIONS.filter(
        (integration) => integration.status === INTEGRATIONS_STATUS.DISABLED,
      ).map((integration) => [
        integration.name,
        z.object({ endpoint: urlValidator }),
      ]),
    ),
  ),
  languages: z.array(languageValidator),
  sentry: z.object({
    dsn:
      commonConfig.env.isProduction || commonConfig.env.isCI
        ? urlValidator
        : urlValidator.optional(),
  }),
  apiKeys: z
    .object({
      expirationPresetKeys: z
        .object({
          placeholder: z.string(),
          values: z.record(z.coerce.number().int(), z.string()),
        })
        .optional(),
    })
    .optional(),
})
const config: z.infer<typeof validationSchema> = {
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL!,
  baseUrl: process.env.NEXT_PUBLIC_WEB_BASE_URL!,
  integrations: {
    [INTEGRATION_NAMES.MS365]: {
      endpoint: process.env.NEXT_PUBLIC_INTEGRATIONS_MS365_ENDPOINT!,
    },
    [INTEGRATION_NAMES.MS365_DEFENDER]: {
      endpoint: process.env.NEXT_PUBLIC_INTEGRATIONS_MSDEFENDER_ENDPOINT!,
    },
  },
  // Cloning the array to avoid ts error about readonly array...
  languages: [...SUPPORTED_LANGUAGES],
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN_WEB!,
  },
  apiKeys: {
    expirationPresetKeys: {
      placeholder: "expiration",
      values: {
        7: "1w",
        30: "1m",
        90: "3m",
        180: "6m",
        365: "1y",
      },
    },
  },
}

export const webConfig = (() => {
  try {
    return validationSchema.parse(config)
  } catch (err) {
    const { errors } = err as z.ZodError
    const formattedErrors = errors.map(
      ({ path, message }) => `\t- ${path.join(".")}: ${message}\n`,
    )

    throw new Error(
      `Error parsing environment variables:\n${formattedErrors.join("")}`,
    )
  }
})()
