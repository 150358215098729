/* eslint-disable max-lines */
import { UUID } from "crypto"
import { Primitive } from "zod"

export const APP_ENVS = {
  DEV: "development",
  TEST: "test",
  PROD: "production",
} as const

export const DEFAULT_LANGUAGE = "en"

export const SUPPORTED_LANGUAGES = ["en", "fr", "zh"] as const

export type Language = (typeof SUPPORTED_LANGUAGES)[number]

export const REPORT_TYPES = {
  ACTIVITY: "ACTIVITY",
  VULNERABILITY: "VULNERABILITY",
} as const

export const HTTP_CODES = {
  // 1xx Informational
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,

  // 2xx Success
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,

  // 3xx Redirection
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,

  // 4xx Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  // 5xx Server Errors
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
} as const

export type HttpCode = (typeof HTTP_CODES)[keyof typeof HTTP_CODES]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FileStream = any

export const INTEGRATIONS_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  SOON: "SOON",
} as const

export type IntegrationStatusType = keyof typeof INTEGRATIONS_STATUS

export const INTEGRATION_NAMES = {
  MS365: "MS365",
  GW: "GW",
  MS365_DEFENDER: "MS365_DEFENDER",
  BITDEFENDER: "BITDEFENDER",
  TRENDMICRO: "TRENDMICRO",
  SOPHOS: "SOPHOS",
} as const

export type IntegrationNamesType =
  (typeof INTEGRATION_NAMES)[keyof typeof INTEGRATION_NAMES]

export const INTEGRATIONS_TYPES = {
  DIGITAL_WORKSPACES: "DIGITAL_WORKSPACES",
  EDR: "EDR",
} as const

export type IntegrationTypesType =
  (typeof INTEGRATIONS_TYPES)[keyof typeof INTEGRATIONS_TYPES]

export interface Integration extends Record<string, unknown> {
  name: IntegrationNamesType
  type: IntegrationTypesType
  status: IntegrationStatusType
}

export const INTEGRATIONS: Integration[] = [
  {
    name: INTEGRATION_NAMES.MS365,
    type: INTEGRATIONS_TYPES.DIGITAL_WORKSPACES,
    status: INTEGRATIONS_STATUS.DISABLED,
  },
  {
    name: INTEGRATION_NAMES.GW,
    type: INTEGRATIONS_TYPES.DIGITAL_WORKSPACES,
    status: INTEGRATIONS_STATUS.SOON,
  },
  {
    name: INTEGRATION_NAMES.MS365_DEFENDER,
    type: INTEGRATIONS_TYPES.EDR,
    status: INTEGRATIONS_STATUS.DISABLED,
  },
  {
    name: INTEGRATION_NAMES.BITDEFENDER,
    type: INTEGRATIONS_TYPES.EDR,
    status: INTEGRATIONS_STATUS.SOON,
  },
  {
    name: INTEGRATION_NAMES.TRENDMICRO,
    type: INTEGRATIONS_TYPES.EDR,
    status: INTEGRATIONS_STATUS.SOON,
  },
  {
    name: INTEGRATION_NAMES.SOPHOS,
    type: INTEGRATIONS_TYPES.EDR,
    status: INTEGRATIONS_STATUS.SOON,
  },
] as const

// This differs from INTEGRATION_STATUS, as it defines
// the status of an instance of an integration.
export const INTEGRATION_PROGRESS_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
}

export const PERMISSION_SCOPES = {
  USER: "USER",
  OWN_COMPANY: "OWN_COMPANY",
  OWN_COMPANY_AND_CLIENTS: "OWN_COMPANY_AND_CLIENTS",
  ALL: "ALL",
} as const

export type PermissionScopes = typeof PERMISSION_SCOPES

export const scopeValueMap = {
  [PERMISSION_SCOPES.USER]: 1,
  [PERMISSION_SCOPES.OWN_COMPANY]: 2,
  [PERMISSION_SCOPES.OWN_COMPANY_AND_CLIENTS]: 3,
  [PERMISSION_SCOPES.ALL]: 4,
}

export const TOTP_TOKEN_STATUS = {
  INVALID_TOKEN: "INVALID_TOKEN",
  VALID_TOKEN: "VALID_TOKEN",
} as const

export const TOTP_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
} as const

// N.B: you can't get the type of a sum, thus using hard values
export type PermissionScope = keyof PermissionScopes

// Only use powers of 2, as we use bitwise operators to compute permissions
export const PERMISSION_ACTIONS = {
  GRANT: "GRANT",
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
} as const

export type PermissionActions = typeof PERMISSION_ACTIONS

export type PermissionAction = keyof PermissionActions

export const MODEL_RESOURCES = {
  ApiKeyModel: "ApiKeyModel",
  CompanyModel: "CompanyModel",
  EdrCredentialModel: "EdrCredentialModel",
  IntegrationModel: "IntegrationModel",
  ReportModel: "ReportModel",
  RoleModel: "RoleModel",
  UserModel: "UserModel",
} as const

export type ModelResources = typeof MODEL_RESOURCES

export type ModelResource = keyof ModelResources

export const RESOURCES = {
  ...MODEL_RESOURCES,
  sessions: "sessions",
} as const

export type Resources = typeof RESOURCES

export type Resource = keyof Resources

export type Permissions = Partial<{
  [KResource in Resource]: Partial<{
    [KAction in PermissionAction]: PermissionScope
  }>
}>

export interface SessionPayload {
  user: {
    id: UUID
    firstName: string
    lastName: string
    role: {
      name: string
      permissions: Permissions
    }
    company: {
      id: UUID
      name: string
    }
  }
}

export interface SessionTokenData {
  token: string
  expiresAt: string
  payload: SessionPayload
}

export type Prettify<T> = {
  [K in keyof T]: T[K] extends Primitive ? T[K] : Prettify<T[K]>
} & {}

export const COMPANY_RELATION_TYPES = {
  DISTRIBUTOR: "DISTRIBUTOR",
  MSP: "MSP",
  CUSTOMER: "CUSTOMER",
} as const

export type CompanyRelationTypes = typeof COMPANY_RELATION_TYPES

export type CompanyRelationType = keyof CompanyRelationTypes

export const ORDER_BY_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
} as const

export type OrderByDirection = keyof typeof ORDER_BY_DIRECTION

export type StringKey<K> = Extract<K, string>

export interface Assets {
  osName: string
  osVersion: string
  osType: "windows" | "linux" | "macos"
}

export type AssetsWithOutVersion = Omit<AssetsCount, "osVersion">

export interface AssetsCount extends Assets {
  count: number
}

export interface AssetsEndOfLife extends AssetsCount {
  endOfLife: string | false
}

export interface Alerts {
  name: string
  count: number
}

export interface CountAlerts {
  falsePositive: number
  truePositive: number
  ongoing: number
  quarantine: number
}

export interface PercentageAlerts {
  falsePositive: string
  truePositive: string
  ongoing: string
  quarantine: string
  total: string
}

export interface CountAssets {
  servers: {
    windows: number
    linux: number
    macos: number
  }
  workstations: {
    windows: number
    linux: number
    macos: number
  }
}

export interface CountVulnerabilities {
  critical: number
  high: number
  medium: number
  low: number
}

export interface EdrData {
  assets: {
    count: CountAssets
    list: {
      servers: AssetsWithOutVersion[]
      workstations: AssetsWithOutVersion[]
      serversObsolescence: AssetsEndOfLife[]
      workstationObsolescence: AssetsEndOfLife[]
    }
  }
  vulnerabilities: CountVulnerabilities | null
  alerts: {
    countAlertsCurrentMonth: CountAlerts
    countAlertsPreviousMonth: CountAlerts
    percentageEvolutionAlerts: PercentageAlerts
    topFiveAlerts: Alerts[]
  }
}

export type NoteWithLetter = "A" | "B" | "C" | "D" | "E"

export interface ReportNote {
  cyberScore: NoteWithLetter
  infraNote: NoteWithLetter
  vulnerabilityNote: NoteWithLetter | null
  lastMonthInfraNote: NoteWithLetter | "N/A"
  lastMonthVulnerabilityNote: NoteWithLetter | "N/A"
}

export interface ReportData extends EdrData {
  notesReport: ReportNote
}

export const EDR_NAMES = {
  SENTINELONE: "SENTINELONE",
  SOPHOS: "SOPHOS",
} as const

export type EDRNames = typeof EDR_NAMES

export type EDRName = keyof typeof EDR_NAMES

export const REST_STATUS = {
  CREATED: "CREATED",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
}
